<template>
  <section>
    <panel title="Toll Data Log">
      <data-table-local
        :columns="_columns"
        :items="items"
        :viewAble="false"
        :newAble="false"
        :deleteAble="false"
        :editAble="false"
      />
    </panel>
  </section>
</template>
<script>
export default {
  async mounted() {
    try {
      this.items = await this.api.get(`/api/logData/systemLog`)
    } catch (error) {
      console.error(error)
      return ""
    }
  },
  data() {
    return {
      items: "",
    }
  },
  computed: {
    _columns() {
      return [
        {
          label: "status",
          field: "status_data.name_th",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "phone number",
          field: "phone_number",
          // tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Message",
          field: "text",
          // tdClass: "text-right",
          thClass: "text-center",
        },

        {
          label: "error message",
          field: "error_message",
          thClass: "text-center",
        },
        {
          label: "Date",
          field: "timestamp",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ]
    },
  },
}
</script>
